import { Image, Page } from '@react-pdf/renderer'
import { pick } from 'lodash-es'
import { tw } from '~/components/pdfs'
import { type Listing, type ReportPdfOptions } from '~/models'

export default function PdfMapPage({
  listings,
  pdfOptions,
  orientation,
}: {
  listings: Listing[]
  pdfOptions: ReportPdfOptions
  orientation: 'portrait' | 'landscape'
}) {
  // const reportMapUrl = 'http://localhost:1208'
  const reportMapUrl =
    'https://us-central1-resimplifi-1566929291680.cloudfunctions.net/associate-report-map'

  const listingArgs = encodeURIComponent(
    JSON.stringify(
      listings.map((listing) =>
        pick(listing, ['location', 'type', 'status', 'propertyTypes'])
      )
    )
  )
  const reportImageUrl = `${reportMapUrl}?orientation=${orientation}&listings=${listingArgs}`

  // useEffect(() => {
  //   if (!reportMapUrl && typeof window !== 'undefined') {
  //     // @ts-ignore
  //     setMapReportUrl(import.meta.env.VITE_REPORT_MAP_URL)
  //   }
  // }, [reportMapUrl])

  if (!reportMapUrl) {
    return null
  }

  return (
    <Page size="LETTER" orientation={orientation}>
      <Image style={tw('h-full w-full')} src={reportImageUrl} />
    </Page>
  )
}
